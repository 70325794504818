import { Price } from "@/modules/palveluhaku/@types/PalveluhakuResult";
import { priceRegex } from "./regex";
// User may use comma as decimal separator in price fields (19,99)
// This helper function transforms comma to period (19.99) as required by the database
export const fixPrice = (price: string | number | null): number | null => {
    if (!price) {
        return null;
    }
    if (typeof price == "number") {
        return price;
    }
    return Number(price.replace(/,/g, "."));
}

export const validatePrices = (prices: (Price | Record<string, never>)[]): boolean => {
    return !prices.some((p: Price | Record<string, never>) => {
        return (
            !p ||
            !p.palvelumuoto ||
            (!p.price_min && !p.price_max) ||
            (p.price_min && !priceRegex.test(p.price_min.toString())) ||
            (p.price_max && !priceRegex.test(p.price_max.toString()))
        );
    });
};
