export const OWNERSHIP = [
    { id: "yleishyodyllinen", name: "Yleishyödyllinen" },
    { id: "yksityinen", name: "Yksityinen" },
    { id: "kunnallinen", name: "Kunnallinen" },
];

export const SPECIALITIES = [
    { id: "elaimia", name: "Eläimiä", icon: "paw" },
    { id: "esteeton", name: "Esteetön", icon: "universal-access" },
    { id: "itsenaistymisasunto", name: "Itsenäistymisasunto", icon: "house-user" },
];

export const PALVELUMUODOT = [
    { id: "ammatillinen-perhekoti", name: "Ammatillinen perhekoti" },
    { id: "laitoshoito", name: "Laitoshoito" },
    { id: "erityistason-laitoshoito", name: "Erityistason laitoshoito" },
    { id: "vaativa-laitoshoito", name: "Vaativa laitoshoito" },
    { id: "vastaanottotoiminta", name: "Vastaanottotoiminta" },
    { id: "koulukoti", name: "Koulukoti" },
    { id: "eho", name: "Erityinen huolenpito (EHO)" },
    {
        id: "ymparivuorokautinen-perhekuntoutus",
        name: "Ympärivuorokautinen perhekuntoutus",
    },
];

export const ERITYISOSAAMISET = [
    { id: "kaytosongelmat", name: "Käytösongelmaisten lasten kuntoutus" },
    { id: "lastenpsykiatria", name: "Lasten- ja/tai nuorisopsykiatria" },
    { id: "neuropsykiatria", name: "Neuropsykiatria" },
    { id: "paihdekatkaisu", name: "Päihdekatkaisu" },
    { id: "paihdekuntoutus", name: "Päihdekuntoutus, päihdeosaaminen" },
    { id: "monikulttuurisuus", name: "Monikulttuurisuus" },
    { id: "kehitysvamma", name: "Kehitysvammaosaaminen" },
    { id: "kouluyksikossa", name: "Koulu yksikön yhteydessä" },
    { id: "jalkihuolto", name: "Jälkihuoltotyöskentely" },
    { id: "ensikoti", name: "Ensikoti" },
];

export const LANGUAGES = [
    { id: "suomi", name: "Suomi" },
    { id: "ruotsi", name: "Ruotsi" },
    { id: "englanti", name: "Englanti" },
    { id: "venäjä", name: "Venäjä" },
    { id: "viro", name: "Viro" },
    { id: "arabia", name: "Arabia" },
    { id: "somali", name: "Somali" },
    { id: "viittomakieli", name: "Viittomakieli" },
];
