import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_select = _resolveComponent("q-select")!

  return (_openBlock(), _createBlock(_component_q_select, {
    modelValue: _ctx.palvelumuoto,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.palvelumuoto = $event)),
    multiple: _ctx.multiple,
    outlined: "",
    "dropdown-icon": "fas fa-chevron-down",
    options: _ctx.palvelumuodot,
    dense: "",
    label: "Palvelumuoto",
    "option-value": "id",
    "option-label": "name",
    "options-dense": "",
    "display-value": _ctx.displayValue
  }, null, 8, ["modelValue", "multiple", "options", "display-value"]))
}